.sb {
    display: flex;
    flex-direction: row;
    position: relative;
    margin: 3vw 10vw;
}

.sb__link {
    width: 100vh;
    max-width: 50px;
    height: auto;
    margin-left: 10px;
}

.sb__image {
    transition: filter .1s linear;
}
.sb__image.facebook {
    filter: invert(50%) sepia(28%) saturate(293%) hue-rotate(181deg) brightness(93%) contrast(87%);

}
.sb__image.facebook:hover {
    filter: invert(28%) sepia(68%) saturate(539%) hue-rotate(183deg) brightness(98%) contrast(88%);
}

/*.sb__image.twitter {
    filter: invert(97%) sepia(99%) saturate(835%) hue-rotate(167deg) brightness(98%) contrast(86%);
}

.sb__image.twitter:hover {
    filter: invert(47%) sepia(69%) saturate(1563%) hue-rotate(162deg) brightness(97%) contrast(101%);
}
*/
.sb__image.youtube {
    filter: invert(99%) sepia(36%) saturate(3797%) hue-rotate(291deg) brightness(104%) contrast(97%);
}

.sb__image.youtube:hover {
    filter: invert(13%) sepia(92%) saturate(7434%) hue-rotate(0deg) brightness(101%) contrast(109%);
}
