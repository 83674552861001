.footer {
    width: 100%;
    background-color: #ffffff;
    position: relative;
    margin-top: 30px;
    display: flex;
    justify-content: center;
    align-items: center;
    left: 0;
    right: 0;
}

.footer__inner {
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    flex-direction: row;
    align-items: center;
}

.footer__logo {
    width: 100%;
    max-width: 200px;
    margin-top: 20px;
}

.footer__contacts {
    font-family: 'Open Sans Condensed', sans-serif;
    white-space: pre-wrap;
    margin-left: 10vw;
    margin-top: 20px;
    margin-bottom: 20px;
    font-size: 20px;
}

@media (max-width: 600px) {
    .footer__inner {
        flex-direction: column;
    }
}
