@font-face {
    font-family: 'Open Sans Condensed';
    font-style: normal;
    font-weight: 300;
    src: url('./resources/fonts/OpenSansCondensed-Light.ttf');
    font-display: swap;
}


body {
    margin: 0;
    font-size: 15px;
    line-height: 1.6;
    background-color: #f5f5f5;

}

*,
*:before,
*:after {
    box-sizing: border-box;
}

.skip-link {
    position: absolute;
    top: -40px;
    left: 0;
    background: #000000;
    color: white;
    padding: 8px;
    z-index: 100;
}

.skip-link:focus {
    top: 0;
}

.container {
    display: flex;
    justify-content: center;
    width: 100%;
    max-width: 1200px;
    margin: 0 auto;
}



h1, h2, h3, h4, h5 , h6 {
    margin: 0;
}


.loading {
    display: flex;
    width: 100%;
    height: 100%;
    position: absolute;
    top: 50%;
    justify-content: center;
    z-index: 99;
}

.loading:after {
    content: " ";
    display: block;
    width: 64px;
    height: 64px;
    margin: 8px;
    border-radius: 50%;
    border: 6px solid;
    border-color: #343434 transparent #343434 transparent;
    animation: loading 1.2s linear infinite;
}
@keyframes loading {
    0% {
        transform: rotate(0deg);
    }
    100% {
        transform: rotate(360deg);
    }
}

.header {
    width: 100%;
    padding-top: 30px;
    padding-bottom: 30px;
    position: relative;
    top: 0;
    left: 0;
    right: 0;
    background-color: white;
}

.header_inner {
    margin-right: 10px;
    margin-left: 10px;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    width: 100%;
    align-items: center;

}



.header_logo {
    width: 40vw;
    max-width: 250px;
    margin-right: 20px;
}

.nav {
    text-transform: uppercase;
    font-size: 25px;

}

.nav_link {
    display: inline-block;
    vertical-align: center;
    margin: 10px;
    color: black;
    text-decoration: none;
    transition: color .1s linear;
    font-family: 'Open Sans Condensed', sans-serif;
}

@media (max-width: 900px) {
    .header_inner {
        flex-direction: column;
    }
    .header_logo {
        margin: 10px;
    }
}

.nav_link:hover {
    color: #8e4646;
}

.nav_link.active{
    color: #8e4646;
}

.nav_link:after{
    content: "";
    display: block;
    width: 100%;
    height: 2px;
    background-color: #8e4646;
    top: 100%;
    left: 0;
    opacity: 0;
    transition: opacity .1s linear;
}

.nav_link:hover:after{
     opacity: 1;
 }

.nav_link.active:after{
    opacity: 1;
}

.language_selection {

}

.language_selection_link {
    display: inline-block;
    vertical-align: center;
    width: 25px;
    height: 25px;
    margin: 5px;
    position: relative;
    opacity: .6;
    filter: grayscale(0.7);
    transition: filter .2s linear;
}

.language_selection_link:hover {
    opacity: 1;
    filter: grayscale(0);
}

.language_selection_link.active {
    opacity: 1;
    filter: grayscale(0);
}



